import request from '@/utils/request'

// 查询用户团豆获得记录列表
export function listGroupBeansRecord(query) {
  return request({
    url: '/group/group/beans/record/list',
    method: 'get',
    params: query
  })
}

// 查询用户团豆获得记录详细
export function getGroupBeansRecord(id) {
  return request({
    url: '/group/group/beans/record/' + id,
    method: 'get'
  })
}

// 新增用户团豆获得记录
export function addGroupBeansRecord(data) {
  return request({
    url: '/group/group/beans/record',
    method: 'post',
    data: data
  })
}

// 修改用户团豆获得记录
export function updateGroupBeansRecord(data) {
  return request({
    url: '/group/group/beans/record',
    method: 'put',
    data: data
  })
}

// 删除用户团豆获得记录
export function delGroupBeansRecord(id) {
  return request({
    url: '/group/group/beans/record/' + id,
    method: 'delete'
  })
}
