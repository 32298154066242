<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="关联id" prop="relationId" >
        <a-input v-model="form.relationId" placeholder="请输入关联id" />
      </a-form-model-item>
      <a-form-model-item label="获得团豆" prop="groupBeans" >
        <a-input v-model="form.groupBeans" placeholder="请输入获得团豆" />
      </a-form-model-item>
      <a-form-model-item label="获得类型" prop="getType">
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type">
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
      </a-form-model-item>
      <a-form-model-item label="到期时间" prop="dueTime">
        <a-date-picker style="width: 100%" v-model="form.dueTime" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGroupBeansRecord, addGroupBeansRecord, updateGroupBeansRecord } from '@/api/group/groupBeansRecord'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        relationId: null,
        groupBeans: null,
        getType: null,
        type: null,
        status: 0,
        dueTime: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        groupBeans: [
          { required: true, message: '获得团豆不能为空', trigger: 'blur' }
        ],
        getType: [
          { required: true, message: '获得类型不能为空', trigger: 'change' }
        ],
        type: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ],
        dueTime: [
          { required: true, message: '到期时间不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        relationId: null,
        groupBeans: null,
        getType: null,
        type: null,
        status: 0,
        dueTime: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGroupBeansRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGroupBeansRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGroupBeansRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>